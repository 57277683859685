import it from './locales/it.json'

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'it',
  fallbackLocale: 'it',
  availableLocales: ['it'],
  messages: {
    it,
  },
}))
